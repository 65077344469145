<template>
  <div class="ant-card" id="course_category">
<a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large" :tip="tip">
    <div class="ant-card-body">
      <div class="head-box">
        <div class="table-operator">
          <a-button
            icon="plus"
            type="primary"
            @click="onAdd"
            v-show="isShowAdd"
            >添加分类
          </a-button>
        </div>
      </div>
      <div class="contant-box">
        <a-table
          ref="table"
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :pagination="false"
        >
        <span slot="cateStatus" slot-scope="cateStatus, record">
          {{ cateStatusMap[cateStatus] }}
        </span>
          <span slot="action" slot-scope="text, record">
            <template>
            <a
            class="margin_right20"
            v-show="record.cateStatus == 1"
            @click="releaseStatusConfirm(record)"
          >
            取消发布
          </a>
          <a
            class="margin_right20"
            v-show="record.cateStatus == 2"
            @click="releaseStatusConfirm(record)"
          >
            发布
          </a>
              <a v-show="isShowUpd" class="btn-edit" @click="onEdit(record)"
                >编辑</a
              >
              <!-- <a v-show="isShowDel" class="btn-delete" @click="onDel(record)"
                >删除</a
              > -->
            </template>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 返回顶部 -->
    <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>
    <router-view/>
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
import {
  GetCourseCategoryApi,
  UpdateCateStatusApi,
} from "@/request/api/coursManage";
export default {
  components: { MyPagination },
  created() {
    console.log("sss")
    if (codeFn("addCategory")) this.isShowAdd = true;
    if (codeFn("/admin/course/label/updLabel")) this.isShowUpd = true;
    if (codeFn("/admin/course/label/delLabel")) this.isShowDel = true;
  },
  data() {
    return {
      spinning: false, // 全局loading
      columns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "所属首页分类",
          dataIndex: "cateName",
          key: "cateName",
        },
         {
          title: "状态",
          dataIndex: "cateStatus",
          key: "cateStatus",
          width: 100,
          scopedSlots: { customRender: "cateStatus" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "150px",
          scopedSlots: { customRender: "action" },
        },
      ],
      cateStatusMap: {
          1: '发布',
          2: '取消发布'
      },
      tableList: [],
      tableLoading: false,
      confirmLoading: false,
      isShowAdd: false, // 修改是否隐藏
      isShowUpd: false, // 新增是否隐藏
      isShowDel: false, // 删除是否隐藏
    };
  },
  mounted() {
    this.getTagList();
  },
  methods: {
       // 列表数据
    getTagList() {
      this.tableLoading = true;
      GetCourseCategoryApi().then((res) => {
        console.log(res)
        if (res.code === 200) {
          this.tableList = res.data;
        }
        console.log(this.tableList)
        this.tableLoading = false;
      });
    },
     // 发布/取消发布弹窗
    releaseStatusConfirm(record) {
        const text = record.releaseStatus === 1 ? '取消发布' : '发布';
        Modal.confirm({
          title: "提示",
          content: `确认${text}吗`,
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk: () => {
            this.updateCateStatus(record);
          }
        });
    },
    updateCateStatus(record){
         // 发布/取消发布状态变更
      UpdateCateStatusApi({
        categoryNo: record.categoryNo
      }).then(({code, msg}) => {
        if (code === 200) {
          this.getTagList();
        } else {
          message.error(msg || "操作失败，请稍后重试");
        }
      });
    },
    onAdd(){
        console.log("goto add")
         this.$router.push("/coursManage/courseCategory/addCategory/0");
    },
    onEdit(record){
        this.$router.push(`/coursManage/courseCategory/addCategory/${record.categoryNo}`);
    },
    onDel(record){},
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#course");
    },
  }
};
</script>
 
<style lang="less" scoped>
#course_category {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  margin-bottom: 30px;
}
.btn-edit {
  margin-right: 30px;
}
</style>