<template>
  <div class="ant-card" id="course_category">
    <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
      <div class="ant-card-body">
        <div class="head-box">
          <div class="table-operator">
            <a-button icon="plus" type="primary" @click="onAdd" v-show="isShowAdd">添加分类</a-button>
          </div>
        </div>
        <div class="contant-box">
          <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id" :loading="tableLoading" :pagination="false">
            <template slot="action" slot-scope="action, record">
              <span class="btn-edit" @click="editClick(record)">编辑</span>
            </template>
          </a-table>
        </div>
      </div>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>
  
    <a-modal v-model="visibleShow" title="新增/编辑" @ok="handleOk" @cancel="cancelOk">
      <a-input v-model="name" placeholder="请输入分类名称" />
    </a-modal>
    
    <router-view/>
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn } from "@/utils/tools";
import {
  categoryAdd,
  categoryList, categoryUpdate,
} from "@/request/api/coursManage";
export default {
  components: { MyPagination },
  created() {
    if (codeFn("/adminv2/lessons/category/add")) this.isShowAdd = true;
    if (codeFn("/adminv2/lessons/category/update")) this.isShowUpd = true;
  },
  data() {
    return {
      spinning: false, // 全局loading
      visibleShow: false,
      status: 0,
      name:'',
      id:'',
      pageNo: 1,
      pageSize: 20,
      count:0,
      columns: [
        {
          title: "名称",
          dataIndex: "categoryName",
          key: "categoryName",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "100px",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableList: [],
      tableLoading: false,
      isShowAdd: false, // 修改是否隐藏
      isShowUpd: false, // 新增是否隐藏
    };
  },
  mounted() {
    this.getTagList();
  },
  methods: {
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getTagList();
    },
    onAdd(){
      this.status = 0
      this.visibleShow = true
    },
    editClick(record){
      this.status = 1
      this.name = record.categoryName
      this.id = record.id
      this.visibleShow = true
    },
    handleOk(){
      if(this.name){
        if(this.status === 0){
          let obj = {
            categoryName: this.name
          }
          categoryAdd(obj).then((res) => {
            if (res.code === 200) {
              this.getTagList()
              this.visibleShow = false
            }
          });
        }else{
          let obj = {
            id: this.id,
            categoryName: this.name
          }
          categoryUpdate(obj).then((res) => {
            if (res.code === 200) {
              this.getTagList()
              this.visibleShow = false
            }
          });
        }
      }else{
        this.$message.warning('请输入分类名称')
      }
    },
    cancelOk(){
      this.id = ''
      this.name = ''
    },
    // 列表数据
    getTagList() {
      this.tableLoading = true;
      let obj = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        categoryName: ''
      }
      categoryList(obj).then((res) => {
        if (res.code === 200) {
          this.tableList = res.data.list;
            this.count = res.data.count;
        }
        this.tableLoading = false;
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#course");
    },
  }
};
</script>

<style lang="less" scoped>
#course_category {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  margin-bottom: 30px;
}
.btn-edit {
  margin-right: 30px;
  color: #1890ff;
}
</style>
